.landing-hero {
  background: linear-gradient(0deg, rgba(51, 51, 51, 0.93), rgba(51, 51, 51, 0.93)), url(/worker-bg.jpg);
  background-position: center;
  margin-top: -130px;
  padding-top: 130px;
  padding-bottom: 100px;
  color: #fff;
  text-align: center;
}

.hero-title {
  font-weight: 800;
  color: #fff;
  text-align: center;
  font-size: 4rem;
  margin-top: 50px;
  line-height: 110%;
  margin-bottom: 25px;
}

.hero-subheader {
  font-size: 1.3rem;
  margin-top: 15px;
  line-height: 129%;
  font-weight: 400;
  margin-bottom: 20px;
}

.hero-ctas button {
  margin-right: 15px;
}

.hero-ctas button, .hero-ctas a {
  font-size: 1.3rem;
  padding: 12px 25px;
}

@media (max-width: 558px) {
  .hero-title {
    font-size: 2.7rem;
  }

  .hero-subheader {
    font-size: 1.1rem;
  }

  .hero-ctas button, .hero-ctas a {
    font-size: 1.1rem;
  }
}

.hero-video-container {
  text-align: center;
  margin-top: -53px;
  position: relative;
}

.hero-video-container img {
  width: 100%;
  /* box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1); */
}

.play-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 0.9rem;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ff7f5055 !important;
  height: 120px;
  width: 120px;
  /*padding: 10px !important;*/
  border-radius: 100%;
  font-size: 2.4rem;
  color: #fff;
  border: none;
}

.halo {
  background: #ff7f50;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin: 0 auto;
}

.play-button:hover {
  width: 130px;
  height: 130px;
  transition: 0.3s ease;
}

.play-icon {
  margin-left: 5px;
  margin-top: 20px;
}

@media (max-width: 558px) {
  .play-text {
    display: none;
  }

  .play-button {
    height: 50px;
    width: 50px;
    font-size: 1rem;
  }

  .halo {
    height: 30px;
    width: 30px;
  }

  .play-icon {
    margin-top: 5px;
    margin-left: 3px;
  }
}

@media (max-width: 568px) {
  .logo {
    height: 90%;
  }
}

.how-it-works {
  padding-top: 25px;
}

.how-title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 100px;
}

.how-subheader {
  text-align: center;
  font-size: 1.3rem;
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 100px;
}

@media (max-width: 700px) {
  .how-subheader {
    width: 100%;
  }
}

.case-study-container {
  background: linear-gradient(90deg, #232526f6 0%, #414345f6 100%), url("/case-study-bg.jpeg");
  padding-top: 10px;
  padding-bottom: 200px;
  margin-top: 200px;
  margin-bottom: -150px;
  position: relative;
  color: #fff;
}

.case-study-container:before {
  background: inherit;
  bottom: 0;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(3deg);
  transform-origin: 100% ;
  z-index: -1;
  top: 0;
  -webkit-backface-visibility: hidden; 

}
/* 
.case-study-container:after {
  background: inherit;
  bottom: 0;
  content: '';
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(3deg);
  transform-origin: 0 0;
  z-index: -1;
  bottom: 0;
  -webkit-backface-visibility: hidden; 
} */


.case-study {
  width: 80%;
  margin: 0 auto;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  color: #333;
}

@media (max-width: 1024px) {
  .case-study {
    width: 100%;
  }
}


.case-study-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.case-study-preview {
  background: #fff;
  padding: 50px;
  height: 100%;
  font-size: 1.25rem;
}

.case-study-quote {
  margin-bottom: 20px;
}

.case-study-person {
  font-weight: bold;
  margin-bottom: 0px;
}

.case-study-person-title {
  opacity: 0.5;
  margin-bottom: 0px;
}

.case-study-cta {
  display: block;
  text-align: center;
  font-size: 1.3rem;
}

.pre-footer-cta {
  display: block;
  text-align: center;
  font-size: 1.3rem;
}

.plan {
  padding: 30px;
  background: #fff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 120px;
  border-radius: 4px;
}

.plan-name {
  font-weight: bold;
  font-size: 1.7rem;
  margin-bottom: 5px;
}

.plan-description {
  font-size: 1.1rem;
  line-height: 1.5rem;
  opacity: 0.5;
}

.plan-cost {
  font-weight: bold;
  font-size: 3rem;
  margin-top: 20px;
  margin-bottom: 25px;
}

.plan-item {
  display: block;
  margin-top: 15px;
  font-size: 1.05rem;
}

.plan-cta {
  margin-top: 30px;
  font-size: 1.15rem;
  padding: 12px 25px !important;
  bottom: 30px;
  position: absolute;
  width: calc(100% - 60px);
  left: 50%;
  transform: translateX(-50%)
}

@media (max-width: 996px) {
  .plan {
    margin-bottom: 30px;
  }
}

.pre-footer {
  background: linear-gradient(90deg, #232526 0%, #414345 100%);
  color: #fff;
  padding-top: 50px;
  /*padding-bottom: 150px;*/
  margin-bottom: -180px;
  z-index: -1;
  margin-top: 70px;
}

.lander-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-header {
  
}

.lander-preheader {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.3rem;
  opacity: 0.5;
}

.lander-subheader {
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 115%;
  margin-top: 20px;
  margin-bottom: 75px;
}

.how-it-works-info {
}

.how-it-works-header {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.how-it-works-description {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}

.how-it-works-img {
  width: 95%;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 225px;
  object-fit: cover;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .how-it-works-img {
    width: 50%;
    margin-top: 25px;
  }

  .how-it-works-description {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .how-it-works-img {
    width: 70%;
  }
}

@media (max-width: 512px) {
  .how-it-works-img {
    width: 80%;
  }
}


@media (max-width: 470px) {
  .how-it-works-img {
    width: 100%;
  }
}

.community-section {
  background: #FFF6F2;
}

.community-header {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 7px;
}

.community-description {
  font-size: 1.3rem;
}

.community-info {
  margin-bottom: 35px;
}

.community-img {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (max-width: 470px) {
  .community-break {
    display: none;
  }
}

.faqs {
  margin-top: 20px;
}

.faq-card {
  margin-bottom: 10px;
  border: none;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  padding: 15px 20px;
  font-size: 1.3rem;
}

.faq-header {
  background: #fff;
  border-radius: 4px;
  border: none;
}

.faq-body {
  padding-top: 20px;
  margin-top: 15px;
  border-top: 1px solid #eee;
  /* background: #eee; */
}

@media (max-width: 768px) {
  .faq-break {
    display: none;
  }

  .faq-card {
    font-size: 1rem;
    padding: 5px 10px;
  }
}

@media (max-width: 992px) {
  .community-img {
    margin-top: 50px;
    justify-content: center;
    text-align: center;
    width: 100%;
    float: unset !important;
  }

  .right, .left {
    padding: 0px;
  }

  .how-info {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .mobile-img {
    display: block;
  }

  .desktop-img {
    display: none;
  }

  .how-item {
    margin-bottom: 100px;
  }

  .plan-two {
    margin-top: 50px;
  }
}

.search-bar {
  margin-top: -90px;
  margin-bottom: 50px;
}


@media (max-width: 1200px) {
  .search-bar {
    margin-top: -50px;
  }
}